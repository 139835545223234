import IStudyData from 'types/IStudyData'

/**
 * Function that allows you to generate, from a Study object,
 * the default values for the form in the header of the Study page.
 */
export default function studyToFormHeader(study: IStudyData) {
  const studyFieldsKeys = ['title', 'note']

  const transformedStudy = Object.keys(study)
    .filter((key) => studyFieldsKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = study[key]

      return obj
    }, {})

  return {
    study: transformedStudy,
  }
}
