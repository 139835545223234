import { useEffect } from 'react'

import { KeyedMutator } from 'swr'

import { useErrorContext } from 'contexts/ErrorContext'
import { stationMutate } from 'hooks/studyMutations'

import StudyRepository from 'repositories/StudyRepository'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'

import orderingHelper from 'utils/orderingHelper'

type StudyDataFormProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  pinnedStations: IStationData[]
}

/**
 * TODO: add an explanation for this component
 */
export default function Reorder(props: StudyDataFormProps) {
  const { study, mutateStudy, pinnedStations } = props

  const { handleAsyncError } = useErrorContext()

  StudyRepository.initialize(handleAsyncError)

  useEffect(() => {
    if (pinnedStations.length > 0) {
      if (!orderingHelper.validatePinnedStationsOrdering(pinnedStations)) {
        const fixedPinnedStations = orderingHelper.getPinnedStationsOrdering(pinnedStations)

        fixedPinnedStations.forEach((fixedPinnedStation) => {
          StudyRepository.updateStation(fixedPinnedStation.id, {
            pin_order: fixedPinnedStation.pin_order,
          } as IStationData).then((stationResponse) =>
            mutateStudy(stationMutate(stationResponse.id, stationResponse), false),
          )
        })
      }
    }
  }, [study?.id])

  return null
}
