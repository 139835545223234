import IStudyData from 'types/IStudyData'

/**
 * Function that allows you to generate, from a Study object,
 * the default values for the form in the body of the Study page.
 */
export default function studyToFormBody(study: IStudyData) {
  const stationFieldsKey = ['title', 'note']

  const toolFieldsKey = ['title', 'note']
  const termFieldsKey = ['term']

  const transformedStations = study.stations.reduce((acc, station) => {
    acc[station.id] = Object.keys(station)
      .filter((key) => stationFieldsKey.includes(key))
      .reduce((obj, key) => {
        obj[key] = station[key]

        return obj
      }, {})

    return acc
  }, {})

  const transformedTools = study.stations
    .flatMap((station) => station.tools || [])
    .reduce((acc, tool) => {
      const toolId = tool.id

      acc[toolId] = Object.keys(tool)
        .filter((key) => toolFieldsKey.includes(key))
        .reduce((toolAcc, key) => {
          toolAcc[key] = tool[key]

          return toolAcc
        }, {})

      return acc
    }, {})

  const transformedTerms = study.stations
    .flatMap((station) => station.tools || [])
    .flatMap((tool) => tool.terms || [])
    .reduce((acc, term) => {
      const termId = term.id

      acc[termId] = Object.keys(term)
        .filter((key) => termFieldsKey.includes(key))
        .reduce((termAcc, key) => {
          termAcc[key] = term[key]

          return termAcc
        }, {})

      return acc
    }, {})

  return {
    stations: transformedStations,
    tools: transformedTools,
    terms: transformedTerms,
  }
}
