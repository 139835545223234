import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import IStationData from 'types/IStationData'
import paths from 'utils/paths'
import IStudyData, {
  IPasteDataInterface,
  IStudyGridItemData,
  IStudyImportData,
} from 'types/IStudyData'

const rootPath = '/studies/stations'

const pasteStations = (
  studyId: string,
  pasteData: IPasteDataInterface,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.studiesPasteStation(studyId),
    pasteData,
  )

const addStationGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.grid.stationGridItemAdd(id),
    gridItemData,
  )

const removeStationGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.grid.stationGridItemRemove(id),
    gridItemData,
  )

const moveStationGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.station.stationsMove(id),
    gridItemData,
  )

const pushStation = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.station.stationsPush(id),
    gridItemData,
  )

const importToolFromTemplateStation = (
  id: string,
  gridItemData: IStudyImportData,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.station.stationsImportToolFromTemplate(id),
    gridItemData,
  )

const importToolFromFileStation = (
  id: string,
  importData: IStudyImportData,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.station.stationsImportToolFromFile(id),
    importData,
  )

const addStation = (data: IStationData): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.station.stations(),
    data,
  )

const deleteStation = (id: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(paths.backend.study.station.stations(id))

const deleteStationTools = (id: string): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.station.stationsDeleteTools(id),
  )

const update = (
  id: string,
  data: IStationData,
  options: { signal?: AbortSignal } = {},
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.put<IStationData, AxiosResponse<IStationData>>(`${rootPath}/${id}/`, data, {
    ...options,
  })

const StationDataService = {
  pasteStations,
  addStationGridItem,
  removeStationGridItem,
  moveStationGridItem,
  pushStation,
  importToolFromTemplateStation,
  importToolFromFileStation,
  addStation,
  deleteStation,
  deleteStationTools,
  update,
}

export default StationDataService
