import { useState } from 'react'

import { Button } from '@mui/material'

import { KeyedMutator } from 'swr'

import AIDialog from 'components/dialog/AIDialog'

import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

type AIDialogControlProps = {
  study: IStudyData
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
}

function AIDialogControl({ study, tool, mutateStudy }: AIDialogControlProps) {
  const [open, setOpen] = useState(false)

  function getToolTitle() {
    const title = tool.ai_personality_title ? tool.ai_personality_title : 'Generic AI'

    return title
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button onClick={handleOpen} variant="outlined">
        {getToolTitle()}
      </Button>

      <AIDialog
        study={study}
        mutateStudy={mutateStudy}
        tool={tool}
        open={open}
        onModalClose={handleClose}
      />
    </>
  )
}

export default AIDialogControl
