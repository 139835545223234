import React, { useRef, useState } from 'react'
import { Box, Button, DialogActions, DialogContentText, Typography } from '@mui/material'
import { t } from '@lingui/macro'
import useStudy from 'hooks/useStudy'
import { stationMutate } from 'hooks/studyMutations'
import IStationData from 'types/IStationData'
import Checkbox from 'components/control/Checkbox'
import { useErrorContext } from 'contexts/ErrorContext'
import DialogComponent from 'components/dialog//DialogComponent'
import StudyRepository from 'repositories/StudyRepository'

type StationConfigDialogProps = {
  onClose: () => void
  open: boolean
  station: IStationData
}

function StationConfigDialog({ onClose, open, station }: StationConfigDialogProps): JSX.Element {
  const [isTemplate, setIsTemplate] = useState<boolean>(station.is_template)
  const { mutate } = useStudy()
  const { handleAsyncError } = useErrorContext()
  const dialogRef = useRef(null)

  const handleSaveDialog = (): void => {
    if (isTemplate !== station.is_template) {
      const stationToUpdate: IStationData = { ...station, ...{ is_template: isTemplate } }

      StudyRepository.updateStation(stationToUpdate.id, stationToUpdate)
        .then((data) => {
          void mutate(stationMutate(station.id, data), false)
          onClose()
        })
        .catch((err) => {
          handleAsyncError(err.message)
        })
    } else {
      onClose()
    }
  }

  const handleCloseDialog = (): void => {
    setIsTemplate(station.is_template)
    onClose()
  }

  return (
    <DialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={false}
      dialogTitle={`${t`Configure station`}${t`:`} ${station.title}`}
    >
      <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 3 }}>
        {t`Here are the additional options available for this station.`}
      </DialogContentText>

      <Box>
        <Checkbox
          checked={isTemplate}
          color="primary"
          label={
            <Typography sx={{ fontSize: '0.95rem' }}>{t`This station is a template`}</Typography>
          }
          labelPlacement="end"
          onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
            setIsTemplate(checked)
          }
          sx={{ py: 0 }}
        />
      </Box>

      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="24px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button variant="contained" onClick={handleSaveDialog} sx={{ width: 'auto' }}>
            {t`Save`}
          </Button>
        </Box>
      </DialogActions>
    </DialogComponent>
  )
}

export default React.memo(StationConfigDialog)
