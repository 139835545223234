import { memo, ReactNode, useCallback, useEffect, useRef } from 'react'

import { debounce, isEqual } from 'lodash'
import { FormProvider, UseFormReturn } from 'react-hook-form'

import { useErrorContext } from 'contexts/ErrorContext'

import studyToFormBody from 'helpers/studyToFormBody'

import StudyRepository from 'repositories/StudyRepository'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import ITermData from 'types/ITermData'
import IToolData from 'types/IToolData'

import { useActionMenuContext } from 'contexts/ActionMenuContext'

export type FormStudyData = {
  stations: { [key: string]: IStationData }
  tools: { [key: string]: IToolData }
  terms: { [key: string]: ITermData }
}

type StudyDataFormBodyProps = {
  study: IStudyData
  studyForm: UseFormReturn<FormStudyData>
  children: ReactNode
}

function StudyDataFormBody({ study, studyForm, children }: StudyDataFormBodyProps) {
  const { setActionMenuContext } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()

  const previousValuesFromBackend = useRef(studyForm.getValues())

  StudyRepository.initialize(handleAsyncError)

  const getDirtyFieldValues = useCallback((studyForm: UseFormReturn<FormStudyData>) => {
    const { dirtyFields } = studyForm.formState
    const formData = studyForm.getValues()

    const dirtyValues = {}

    Object.keys(dirtyFields).forEach((section) => {
      Object.keys(dirtyFields[section]).forEach((id) => {
        if (formData[section] && formData[section][id]) {
          const dirtyFieldsInSection = dirtyFields[section][id]
          const formValuesInSection = formData[section][id]

          Object.keys(dirtyFieldsInSection).forEach((field) => {
            if (dirtyFieldsInSection[field]) {
              if (!dirtyValues[section]) {
                dirtyValues[section] = {}
              }
              if (!dirtyValues[section][id]) {
                dirtyValues[section][id] = {}
              }
              dirtyValues[section][id][field] = formValuesInSection[field]
            }
          })
        }
      })
    })

    return dirtyValues
  }, [])

  // Replaces the values in study with the dirtyValues from the form
  const replaceValues = (
    study: {
      stations: {}
      tools: {}
      terms: {}
    },
    dirtyValues: Partial<IStudyData>,
  ) => {
    Object.keys(dirtyValues).forEach((section) => {
      if (study[section]) {
        Object.keys(dirtyValues[section]).forEach((id) => {
          if (study[section][id]) {
            Object.keys(dirtyValues[section][id]).forEach((field) => {
              if (study[section][id][field] !== undefined) {
                study[section][id][field] = dirtyValues[section][id][field]
              }
            })
          }
        })
      }
    })
  }

  useEffect(() => {
    const values = studyToFormBody(study)

    if (JSON.stringify(previousValuesFromBackend.current) !== JSON.stringify(values)) {
      const dirtyValues = getDirtyFieldValues(studyForm)

      replaceValues(values, dirtyValues)

      studyForm.reset(values)
      previousValuesFromBackend.current = values
    }
  }, [study, studyForm])

  const updateContext = (objectID: string, data: IStationData | IToolData | ITermData) => {
    setActionMenuContext((prevState) => {
      const updatedState = { ...prevState }

      Object.keys(updatedState).forEach((key) => {
        if (updatedState[key] && updatedState[key].id === objectID) {
          updatedState[key] = { ...updatedState[key], ...data }
        }
      })

      return updatedState
    })
  }

  const handleSubmit = useCallback(
    (data: FormStudyData) => {
      const { dirtyFields } = studyForm.formState

      if (dirtyFields) {
        if (dirtyFields.stations) {
          Object.keys(dirtyFields.stations).forEach((stationId) => {
            const stationData = data.stations[stationId]

            if (isEqual(previousValuesFromBackend.current.stations[stationId], stationData)) return

            updateContext(stationId, stationData)
            void StudyRepository.updateStation(stationId, stationData)
          })
        }

        if (dirtyFields.tools) {
          Object.keys(dirtyFields.tools).forEach((toolId) => {
            const toolData = data.tools[toolId]

            if (isEqual(previousValuesFromBackend.current.tools[toolId], toolData)) return

            updateContext(toolId, toolData)
            void StudyRepository.updateTool(toolId, toolData)
          })
        }

        if (dirtyFields.terms) {
          Object.keys(dirtyFields.terms).forEach((termId) => {
            const termData = data.terms[termId]

            if (isEqual(previousValuesFromBackend.current.stations[termId], termData)) return

            updateContext(termId, termData)
            void StudyRepository.updateTerm(termId, termData)
          })
        }
      }
    },
    [studyForm.formState.dirtyFields],
  )

  useEffect(() => {
    const callback = debounce((formValues) => handleSubmit(formValues), 500)

    const { unsubscribe } = studyForm.watch(callback)

    return () => unsubscribe()
  }, [studyForm.watch])

  return <FormProvider {...studyForm}>{children}</FormProvider>
}

export default memo(StudyDataFormBody)
