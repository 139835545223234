import { Box } from '@mui/material'

import { KeyedMutator } from 'swr'

import DialogComponent from 'components/dialog/DialogComponent'

import AIForm from 'components/form/AIForm'

import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

type AIDialogProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  tool: IToolData
  open: boolean
  onModalClose: () => void
}

export default function AIDialog(props: AIDialogProps) {
  const { study, mutateStudy, tool, open, onModalClose } = props

  return (
    <DialogComponent
      open={open}
      onModalClose={onModalClose}
      scroll="paper"
      sx={{ margin: 0 }}
      isLoading={false}
    >
      <Box sx={{ width: { xs: 'auto', md: '500px' } }}>
        <AIForm study={study} tool={tool} onModalClose={onModalClose} mutateStudy={mutateStudy} />
      </Box>
    </DialogComponent>
  )
}
