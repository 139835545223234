import {
  FormControl,
  FormControlLabel,
  Checkbox,
  CheckboxProps as MuiCheckboxProps,
  Box,
  FormControlLabelProps,
} from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'

import { colors } from '../../shared/theme'

export type CheckboxProps = MuiCheckboxProps &
  Partial<Pick<FormControlLabelProps, 'label' | 'labelPlacement'>> & {
    labelSx?: SxProps<Theme>
  }

function FormCheckbox({ label, labelPlacement = 'end', labelSx = {}, ...others }: CheckboxProps) {
  return (
    <Box>
      {label ? (
        <FormControl>
          <FormControlLabel
            control={<Checkbox {...others} />}
            label={label}
            labelPlacement={labelPlacement}
            sx={labelSx}
          />
        </FormControl>
      ) : (
        <Checkbox
          {...others}
          sx={{
            margin: 0,
            padding: 0,
            color: colors.lightBlue.A600,
            '&.Mui-checked': {
              color: colors.blue.A500,
            },
          }}
        />
      )}
    </Box>
  )
}

export default FormCheckbox
