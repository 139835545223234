import { memo, useCallback } from 'react'

import { t } from '@lingui/macro'
import { Box, LinearProgress, Typography } from '@mui/material'

import { useForm } from 'react-hook-form'
import { KeyedMutator } from 'swr'

import TextFieldComponent from 'components/control/TextFieldComponent'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { useErrorContext } from 'contexts/ErrorContext'

import TermDataService from 'data-services/TermDataService'
import ToolDataService from 'data-services/ToolDataService'

import { toolMutate } from 'hooks/studyMutations'

import { colors } from 'shared/theme'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import ITermData from 'types/ITermData'
import IToolData from 'types/IToolData'
import StudyRepository from 'repositories/StudyRepository'

type ToolProps = {
  tool: IToolData
  station: IStationData
  mutateStudy: KeyedMutator<IStudyData>
}

type FormToolFields = {
  prompt: string
}

/* Carte dans un tool */
function NewTerm({ tool, station, mutateStudy }: ToolProps): JSX.Element {
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()

  const { handleAsyncError } = useErrorContext()

  const {
    control: promptControl,
    getValues,
    reset,
  } = useForm<FormToolFields>({
    mode: 'onBlur',
    defaultValues: {
      prompt: tool.prompt,
    },
  })

  const addTerm = (newTerm: ITermData) =>
    new Promise<boolean>((resolve, reject) => {
      TermDataService.addTerm(newTerm)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const hasRobot = useCallback(() => tool.tool_type !== '', [tool.tool_type])

  const isProcessingText = () => {
    if (tool.is_processing) return t`Processing...`
    if (hasRobot()) return t`Type question...`
    return t`Type text...`
  }

  const handlePromptSubmit = useCallback(() => {
    if (hasRobot()) {
      const toolData = getValues() as IToolData

      StudyRepository.updateStation(station.id, { ...station, subject: toolData.prompt })
        .then(() => {
          ToolDataService.processTool(tool.id, toolData)
            .then(({ data }) => {
              void mutateStudy(toolMutate(tool.station, tool.id, data), false)

              if (actionMenuContext.tool?.id === tool.id) {
                setActionMenuContext((prevActionMenuContext) => ({
                  ...prevActionMenuContext,
                  tool: data,
                }))
              }
            })
            .catch((error: Error) => {
              handleAsyncError(error.message)
            })
        })
        .catch((error: Error) => {
          handleAsyncError(error.message)
        })
    } else {
      const term = getValues('prompt')

      if (!term) {
        return
      }

      const newTerm = {
        id: '',
        term,
        order: 0,
        note: '',
        hyperlink: '',
        tool: tool.id,
        is_error: false,
        agent_meta: '',
        agent_model: '',
        agent_tokens: '',
      }

      addTerm(newTerm).then(() => {
        mutateStudy().then((studyResponse) => {
          reset()

          if (actionMenuContext.tool?.id === tool.id) {
            const newStation = studyResponse.stations.find(
              (stationObject) => stationObject.id === station.id,
            )

            const newTool = newStation?.tools.find((toolObject) => toolObject.id === tool.id)

            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              tool: newTool,
            }))
          }
        })
      })
    }
  }, [])

  return (
    <>
      <Box
        padding="4px 8px"
        sx={{
          display: 'flex',
          background: colors.gray.A700,
          borderRadius: '4px 4px 0px 0px',
          borderBottom: `1px solid ${colors.black.A500}`,
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Typography variant="h3" sx={{ color: colors.black.A600 }}>
            {hasRobot() ? t`Prompt` : t`New Term`}
          </Typography>

          <TextFieldComponent
            label=""
            placeholder={isProcessingText()}
            className="MuiInput-test"
            control={promptControl as never}
            name="prompt"
            type="text"
            requiredMsg={t`Term is required`}
            multiline
            sx={{ width: '100%' }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handlePromptSubmit()
              }
            }}
            disabled={tool.is_processing}
          />
        </Box>
      </Box>

      {tool.is_processing && <LinearProgress sx={{ margin: '8px 0 0 0' }} />}
    </>
  )
}

export default memo(NewTerm)
