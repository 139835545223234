import IStationData from 'types/IStationData'
import StationDataService from 'data-services/StationDataService'
import ToolDataService from 'data-services/ToolDataService'
import TermDataService from 'data-services/TermDataService'
import IToolData from '../types/IToolData'
import IStudyData, { IPasteDataInterface } from '../types/IStudyData'
import ITermData, { ITermUploadStartData, ITermUploadStartResponseData } from '../types/ITermData'
import StudyDataService from '../data-services/StudyDataService'

export default class StudyRepository {
  private static handleError: (errorMessage: string) => void = (errorMessage) => {
    console.error(errorMessage)
  }

  static initialize(handleAsyncError: (errorMessage: string) => void): void {
    StudyRepository.handleError = handleAsyncError
  }

  private static abortControllers: { [key: string]: AbortController } = {}

  // Cancel the previous request for a specific study
  private static cancelPreviousRequest(id: string) {
    if (StudyRepository.abortControllers[id]) {
      StudyRepository.abortControllers[id].abort('Request cancelled due to a new update.')
      StudyRepository.removeKeyFromAbortControllers(id)
    }
  }

  private static removeKeyFromAbortControllers(key: string) {
    delete StudyRepository.abortControllers[key]
  }

  static async createStudy(studyToCreate: IStudyData): Promise<IStudyData> {
    try {
      const { data: studyResponse } = await StudyDataService.addStudy(studyToCreate)
      return studyResponse
    } catch (error) {
      StudyRepository.handleError(error.message)
      return Promise.reject(error)
    }
  }

  static updateStudy(studyId: string, studyToUpdate: IStudyData): Promise<IStudyData> {
    return new Promise<IStudyData>((resolve, reject) => {
      StudyRepository.cancelPreviousRequest(studyId)

      const controller = new AbortController()
      StudyRepository.abortControllers[studyId] = controller

      StudyDataService.updateStudy(studyId, studyToUpdate, {
        signal: controller.signal,
      })
        .then(({ data: studyResponse }) => {
          StudyRepository.removeKeyFromAbortControllers(studyId)
          resolve(studyResponse)
        })
        .catch((error) => {
          if (error.name === 'CanceledError') {
            console.log('Request cancelled:', error.message)
            resolve(error)
            return
          }

          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateStation(stationId: string, stationToUpdate: IStationData): Promise<IStationData> {
    return new Promise<IStationData>((resolve, reject) => {
      StudyRepository.cancelPreviousRequest(stationId)

      const controller = new AbortController()
      StudyRepository.abortControllers[stationId] = controller

      StationDataService.update(stationId, stationToUpdate, {
        signal: controller.signal,
      })
        .then(({ data: stationResponse }) => {
          StudyRepository.removeKeyFromAbortControllers(stationId)
          resolve(stationResponse)
        })
        .catch((error) => {
          if (error.name === 'CanceledError') {
            console.log('Request cancelled:', error.message)
            resolve(error)
            return
          }

          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateTool(toolId: string, toolToUpdate: IToolData): Promise<IToolData> {
    return new Promise<IToolData>((resolve, reject) => {
      StudyRepository.cancelPreviousRequest(toolId)

      const controller = new AbortController()
      StudyRepository.abortControllers[toolId] = controller

      ToolDataService.update(toolId, toolToUpdate, {
        signal: controller.signal,
      })
        .then(({ data: toolResponse }) => {
          StudyRepository.removeKeyFromAbortControllers(toolId)
          resolve(toolResponse)
        })
        .catch((error) => {
          if (error.name === 'CanceledError') {
            console.log('Request cancelled:', error.message)
            resolve(error)
            return
          }

          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static updateTerm(termId: string, termToUpdate: ITermData): Promise<ITermData> {
    return new Promise<ITermData>((resolve, reject) => {
      StudyRepository.cancelPreviousRequest(termId)

      const controller = new AbortController()
      StudyRepository.abortControllers[termId] = controller

      TermDataService.update(termId, termToUpdate, {
        signal: controller.signal,
      })
        .then(({ data: termResponse }) => {
          StudyRepository.removeKeyFromAbortControllers(termId)
          resolve(termResponse)
        })
        .catch((error) => {
          if (error.name === 'CanceledError') {
            console.log('Request cancelled:', error.message)
            resolve(error)
            return
          }

          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static pasteTerms(toolId: string, data: IPasteDataInterface): Promise<IStudyData> {
    return new Promise<IStudyData>((resolve, reject) => {
      TermDataService.pasteTerms(toolId, data)
        .then(({ data: studyResponse }) => {
          resolve(studyResponse)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static fileUploadStartTerm(
    termId: string,
    data: ITermUploadStartData,
  ): Promise<ITermUploadStartResponseData> {
    return new Promise<ITermUploadStartResponseData>((resolve, reject) => {
      TermDataService.fileUploadStartTerm(termId, data)
        .then(({ data: fileUploadStartResponse }) => {
          resolve(fileUploadStartResponse)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }

  static deleteTerm(termId: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      TermDataService.deleteTerm(termId)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          StudyRepository.handleError(error.message)
          reject(error)
        })
    })
  }
}
