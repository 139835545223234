import React, { createContext, useState, useContext, useMemo } from 'react'

interface studyStatesContextType {
  isDragging: boolean
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
  pasteMenuCount: number
  setPasteMenuCount: React.Dispatch<React.SetStateAction<number>>
  isPinnedStationDrawerOpen: boolean
  setPinnedStationDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const studyStatesContext = createContext<studyStatesContextType | undefined>(undefined)

export const useStudyStatesContext = () => {
  const context = useContext(studyStatesContext)

  if (!context) {
    throw new Error('useStudyStatesContext must be used within a StudyStatesContextProvider')
  }

  return context
}

export const StudyStatesContextProvider: React.FC = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [pasteMenuCount, setPasteMenuCount] = useState(0)
  const [isPinnedStationDrawerOpen, setPinnedStationDrawerOpen] = useState(false)

  const value = useMemo(
    () => ({
      isDragging,
      setIsDragging,

      pasteMenuCount,
      setPasteMenuCount,

      isPinnedStationDrawerOpen,
      setPinnedStationDrawerOpen,
    }),
    [isDragging, pasteMenuCount, isPinnedStationDrawerOpen],
  )

  return <studyStatesContext.Provider value={value}>{children}</studyStatesContext.Provider>
}
