import { useEffect } from 'react'

import { useMediaQuery } from '@mui/material'

import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import IStationData from 'types/IStationData'

type DrawerOpeningControlProps = {
  pinnedStations: IStationData[]
}

/**
 * Allows to control the Drawer opening for pinned stations, according to the screen size.
 *
 * Also works when resizing the window.
 */
export default function DrawerOpeningControl({ pinnedStations }: DrawerOpeningControlProps) {
  const matches = useMediaQuery('(min-width: 900px)')

  const { setPinnedStationDrawerOpen } = useStudyStatesContext()

  useEffect(() => {
    setPinnedStationDrawerOpen(matches && pinnedStations.length > 0)
  }, [matches])

  return null
}
