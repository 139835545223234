import { useCallback, useRef, useState } from 'react'

import { Box, Button, Drawer, IconButton, Typography, useMediaQuery } from '@mui/material'
import { actionMenuDrawerStyle, actionMenuStyle } from 'shared/components/studyStyle'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  AddBoxOutlined as AddBoxIcon,
  QueueOutlined as QueueIcon,
  PushPinOutlined as PushPinIcon,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import ActionMenuViewSizeItem from 'components/action-menu/ActionMenuViewSizeItem'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import ActionMenuStation from 'components/action-menu/ActionMenuStation'
import IStudyData, { serializeStudy } from 'types/IStudyData'
import ActionMenuTool from 'components/action-menu/ActionMenuTool'
import ActionMenuStationRight from 'components/action-menu/ActionMenuStationRight'
import { KeyedMutator } from 'swr'
import ActionMenuTerm from 'components/action-menu/ActionMenuTerm'
import ActionMenuStudyRight from 'components/action-menu/ActionMenuStudyRight'
import ActionMenuStudy from 'components/action-menu/ActionMenuStudy'
import ActionMenuToolRight from 'components/action-menu/ActionMenuToolRight'
import ActionMenuTermRight from 'components/action-menu/ActionMenuTermRight'
import TermDialog from 'components/dialog/TermDialog'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'
import { IClipboard } from 'contexts/ClipboardContext'
import { useTheme } from '@mui/material/styles'
import ConditionalWrapper from '../ConditionalWrapper'

type ActionMenuProps = {
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
}

export default function ActionMenu({ study, mutateStudy, clipboard }: ActionMenuProps) {
  const { actionMenuContext, isContextMenuLoading } = useActionMenuContext()
  const { isPinnedStationDrawerOpen, setPinnedStationDrawerOpen } = useStudyStatesContext()
  const [termDialogOpen, setTermDialogOpen] = useState(false)
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false)

  const actionButtonViewSizeRef = useRef<HTMLButtonElement>(null)
  const [actionButtonViewSizeOpen, setActionButtonViewSizeOpen] = useState(false)
  const actionButtonViewSizeStationRef = useRef<HTMLButtonElement>(null)
  const [actionButtonViewSizeStationOpen, setActionButtonViewSizeStationOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'))

  const memoizedWrapper = useCallback(
    (children) => (
      <Drawer variant="persistent" open={menuDrawerOpen} sx={actionMenuDrawerStyle()}>
        {children}
      </Drawer>
    ),
    [menuDrawerOpen, actionMenuDrawerStyle],
  )

  const handlePinClick = () => {
    setPinnedStationDrawerOpen(!isPinnedStationDrawerOpen)
  }

  return (
    <Box sx={actionMenuStyle()}>
      {isMobile && (
        <Box
          width="100%"
          padding="0 16px 0 0"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '30px' }}
        >
          <IconButton
            color="inherit"
            onClick={() => setMenuDrawerOpen(!menuDrawerOpen)}
            sx={{ padding: 0 }}
          >
            {menuDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
      )}

      <ConditionalWrapper condition={isMobile} wrapper={memoizedWrapper}>
        {!actionMenuContext.termGridItem && !actionMenuContext.term && (
          <ActionMenuButton
            actionMenuButtonRef={actionButtonViewSizeRef}
            actionMenuButtonAnchorOpen={actionButtonViewSizeOpen}
            setActionMenuButtonAnchorOpen={setActionButtonViewSizeOpen}
            handleActionMenuButtonClick={() =>
              setActionButtonViewSizeOpen(!actionButtonViewSizeOpen)
            }
            StartIcon={AddBoxIcon}
            title={t`Add Station`}
            minWidth={{ xs: '120px', md: '140px' }}
            isButtonDisabled={isContextMenuLoading}
            actionMenuItems={
              <Box>
                <Box>
                  <ActionMenuViewSizeItem
                    viewSizeKey="study"
                    setActionButtonViewSizeOpen={setActionButtonViewSizeOpen}
                    study={study}
                    mutateStudy={mutateStudy}
                  />
                </Box>
              </Box>
            }
          />
        )}

        {actionMenuContext.station && !actionMenuContext.term && (
          <ActionMenuButton
            actionMenuButtonRef={actionButtonViewSizeStationRef}
            actionMenuButtonAnchorOpen={actionButtonViewSizeStationOpen}
            setActionMenuButtonAnchorOpen={setActionButtonViewSizeStationOpen}
            handleActionMenuButtonClick={() =>
              setActionButtonViewSizeStationOpen(!actionButtonViewSizeStationOpen)
            }
            StartIcon={QueueIcon}
            title={t`Add Tool`}
            minWidth={{ xs: '120px', md: '140px' }}
            isButtonDisabled={isContextMenuLoading}
            actionMenuItems={
              <Box>
                <Box>
                  <ActionMenuViewSizeItem
                    viewSizeKey="station"
                    setActionButtonViewSizeOpen={setActionButtonViewSizeStationOpen}
                    study={study}
                    mutateStudy={mutateStudy}
                  />
                </Box>
              </Box>
            }
          />
        )}

        {!actionMenuContext.stationGridItem &&
          !actionMenuContext.toolGridItem &&
          !actionMenuContext.term && <ActionMenuStudy study={study} mutateStudy={mutateStudy} />}

        {actionMenuContext.stationGridItem &&
          !actionMenuContext.toolGridItem &&
          !actionMenuContext.term && (
            <ActionMenuStation study={study} mutateStudy={mutateStudy} clipboard={clipboard} />
          )}

        {actionMenuContext.stationGridItem && actionMenuContext.toolGridItem && (
          <ActionMenuTool mutateStudy={mutateStudy} clipboard={clipboard} />
        )}

        {actionMenuContext.term && (
          <ActionMenuTerm
            mutateStudy={mutateStudy}
            setTermDialogOpen={setTermDialogOpen}
            clipboard={clipboard}
          />
        )}

        <Box
          display="flex"
          gap={
            (actionMenuContext.stationGridItem && !actionMenuContext.station) ||
            (actionMenuContext.toolGridItem && !actionMenuContext.tool)
              ? '0'
              : { xs: '8px', mobile: '32px' }
          }
          flexGrow="1"
          alignItems="center"
          justifyContent="flex-end"
          flexWrap={{ xs: 'wrap', mobile: 'nowrap' }}
        >
          {!isMobile && (
            <ActionMenuButton
              handleActionMenuButtonClick={handlePinClick}
              StartIcon={PushPinIcon}
              title={isPinnedStationDrawerOpen ? t`Hide Pinned Space` : t`Show Pinned Space`}
              isButtonActive={isPinnedStationDrawerOpen}
            />
          )}

          <Box sx={{ width: { xs: '100%', mobile: 'auto', textAlign: 'center' } }}>
            <Button
              href={`data:text/json;charset=utf-8,${encodeURIComponent(serializeStudy(study))}`}
              download={t`STS Board (${study?.title}).json`}
              disabled={false}
              variant="textBlock"
              type="button"
              sx={{ width: 'auto', padding: '8px 24px', minWidth: { xs: '100%', mobile: '80px' } }}
              startIcon={
                <DriveFolderUploadIcon
                  fontSize="large"
                  className="MuiButton-startIcon-active MuiButton-startIcon-fixedHeight"
                />
              }
              component="a"
            >
              <Typography
                variant="subtitle2"
                color="text.primary"
                letterSpacing="0.5px"
                className="MuiTypography-active"
              >
                {t`Save Board`}
              </Typography>
            </Button>
          </Box>

          {!actionMenuContext.stationGridItem &&
            !actionMenuContext.toolGridItem &&
            !actionMenuContext.term && (
              <ActionMenuStudyRight study={study} mutateStudy={mutateStudy} />
            )}

          {actionMenuContext.stationGridItem &&
            !actionMenuContext.toolGridItem &&
            !actionMenuContext.term && <ActionMenuStationRight />}

          {actionMenuContext.toolGridItem &&
            !actionMenuContext.termGridItem &&
            !actionMenuContext.term && (
              <ActionMenuToolRight mutateStudy={mutateStudy} clipboard={clipboard} />
            )}

          {actionMenuContext.term && <ActionMenuTermRight clipboard={clipboard} />}
        </Box>

        {actionMenuContext.term && (
          <TermDialog
            study={study}
            mutateStudy={mutateStudy}
            open={termDialogOpen}
            onModalClose={() => setTermDialogOpen(false)}
          />
        )}
      </ConditionalWrapper>
    </Box>
  )
}
