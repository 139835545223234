import { ChangeEvent, MouseEvent, memo, useRef, useState } from 'react'
import { t } from '@lingui/macro'

import { styled } from '@mui/material/styles'
import {
  Box,
  Button,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { GridOn as GridOnIcon, ViewHeadline as ViewHeadlineIcon } from '@mui/icons-material'

import DialogComponent from 'components/dialog/DialogComponent'

import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'

const classes = {
  tableFormatCol: 'ToolCopyDialog-tableFormatCol',
  tableFormatFirstCol: 'ToolCopyDialog-tableFormatFirstCol',
  tableFormatLastCol: 'ToolCopyDialog-tableFormatLastCol',
}

const StyledDialogComponent = styled(DialogComponent)({
  [`& .${classes.tableFormatCol}`]: {
    fontSize: '0.95rem',
    padding: '0 5px',
  },
  [`& .${classes.tableFormatFirstCol}`]: {
    fontSize: '0.95rem',
    padding: '0 5px 0 0',
  },
  [`& .${classes.tableFormatLastCol}`]: {
    fontSize: '0.95rem',
    padding: '0 0 0 5px',
  },
})

enum DisplayFormats {
  Paragraph = 'Paragraph',
  Table = 'Table',
}

type ToolDisplayDialogProps = {
  onClose: () => void
  open: boolean
  tool: IToolData
}

function ToolCopyDialog({ onClose, open, tool }: ToolDisplayDialogProps) {
  const dialogRef = useRef(null)

  const [displayFormat, setDisplayFormat] = useState(DisplayFormats.Paragraph)
  const [includeHeaders, setIncludeHeaders] = useState(true)

  const handleDisplayFormatChanged = (
    e: MouseEvent<HTMLElement>,
    value: DisplayFormats | null,
  ): void => {
    if (value !== null) {
      setDisplayFormat(value)
    }
  }

  const handleIncludeHeadersChanged = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    setIncludeHeaders(checked)
  }

  const handleCopyAllTerms = (): void => {
    const el: HTMLElement | null = document.getElementById('select-all-terms')
    if (el) {
      if (window.getSelection) {
        if ((el as HTMLTextAreaElement).value) {
          ;(el as HTMLTextAreaElement).select()
        }
        const selection: Selection | null = window.getSelection()
        if (selection) {
          const range: Range = document.createRange()
          range.selectNodeContents(el)
          selection.removeAllRanges()
          selection.addRange(range)
          document.execCommand('copy')
        }
      } else {
        console.warn('Could not select: Unsupported browser.')
      }
    }
  }

  const handleCloseDialog = () => {
    onClose()
  }

  const renderPlainText = () => {
    let value: string = ''
    tool.terms.forEach((term: ITermData, index: number) => {
      if (index > 0) {
        value += `${'\n'}`
      }
      value += term.term
    })
    return (
      <TextField
        id="select-all-terms"
        InputProps={{ disableUnderline: true }}
        multiline
        value={value}
        variant="standard"
      />
    )
  }

  const getToolDialogCopyTitle = () => {
    if (tool.tool_type) return tool.tool_type

    if (tool.title) return tool.title

    return t`Tool title`
  }

  const renderTable = () => (
    <table id="select-all-terms">
      {includeHeaders ? (
        <thead>
          <tr>
            <th
              className={classes.tableFormatFirstCol}
              style={{ textAlign: 'left' }}
            >{t`Title`}</th>
            <th className={classes.tableFormatCol} style={{ textAlign: 'left' }}>{t`Note`}</th>
            <th
              className={classes.tableFormatLastCol}
              style={{ textAlign: 'left' }}
            >{t`Hyperlink`}</th>
          </tr>
        </thead>
      ) : (
        <thead />
      )}
      <tbody>
        {tool.terms.map((term: ITermData) => (
          <tr key={term.id}>
            <td className={classes.tableFormatFirstCol} style={{ textAlign: 'left' }}>
              {term.term}
            </td>
            <td className={classes.tableFormatCol} style={{ textAlign: 'left' }}>
              {term.note}
            </td>
            <td className={classes.tableFormatLastCol} style={{ textAlign: 'left' }}>
              {term.hyperlink}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )

  return (
    <StyledDialogComponent
      open={open}
      onModalClose={handleCloseDialog}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={false}
      dialogTitle={getToolDialogCopyTitle()}
    >
      <DialogContentText id="alert-dialog-description" sx={{ fontSize: '0.95rem', pb: 3 }}>
        {tool.subtitle}

        <Box sx={{ textAlign: 'center' }}>
          <ToggleButtonGroup
            color="primary"
            exclusive
            onChange={handleDisplayFormatChanged}
            size="small"
            value={displayFormat}
          >
            <ToggleButton value={DisplayFormats.Paragraph} sx={{ px: 2 }}>
              <ViewHeadlineIcon sx={{ mr: 1 }} />
              {t`Paragraph`}
            </ToggleButton>
            <ToggleButton value={DisplayFormats.Table} sx={{ px: 2 }}>
              <GridOnIcon fontSize="small" sx={{ mr: 1 }} />
              {t`Table`}
            </ToggleButton>
          </ToggleButtonGroup>
          {displayFormat === DisplayFormats.Table ? (
            <Box sx={{ ml: 3, mt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeHeaders}
                    onChange={handleIncludeHeadersChanged}
                    size="small"
                  />
                }
                label={
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Include column headers`}</Typography>
                }
              />
            </Box>
          ) : (
            <span />
          )}
        </Box>
      </DialogContentText>
      {displayFormat === DisplayFormats.Table ? renderTable() : renderPlainText()}
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" margin="24px 0 8px 0" gap="8px">
          <Button
            variant="containedWhite"
            type="button"
            sx={{ width: 'auto' }}
            onClick={() => handleCloseDialog()}
          >
            {t`Cancel`}
          </Button>

          <Button variant="contained" onClick={handleCopyAllTerms} sx={{ width: 'auto' }}>
            {t`Copy All Terms`}
          </Button>
        </Box>
      </DialogActions>
    </StyledDialogComponent>
  )
}

export default memo(ToolCopyDialog)
