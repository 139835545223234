import React, { useEffect, useRef } from 'react'

import { Box, Grid, Paper } from '@mui/material'

import { KeyedMutator } from 'swr'

import Tool from 'components/tool/Tool'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard } from 'contexts/ClipboardContext'
import { useHeaderHeight } from 'contexts/HeaderHeight'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import orderingHelper from 'utils/orderingHelper'
import useWindowDimensions from 'utils/useWindowDimensions'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

import stationStyle from 'shared/components/stationStyle'
import { toolGridItemStyle } from 'shared/components/toolStyle'

import StationHeader from './StationHeader'

type StationProps = {
  station: IStationData
  study: IStudyData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

/*
 * Un cadre
 */
function Station({ station, study, mutateStudy, clipboard, viewType }: StationProps): JSX.Element {
  const { height: topHeaderHeight } = useHeaderHeight()

  const isInitialStationRender = useRef(true)

  const { width: screenWidth, height: screenHeight } = useWindowDimensions()

  const { actionMenuContext, setActionMenuContext, formatActionMenuGridKey } =
    useActionMenuContext()
  const { isPinnedStationDrawerOpen } = useStudyStatesContext()

  useEffect(() => {
    if (isInitialStationRender.current) {
      isInitialStationRender.current = false
    } else if (clipboard.stations.getItemState(station.id) !== null) {
      clipboard.stations.updateItemData(station)
    }
  }, [station])

  const isStationActive = (): boolean =>
    !!actionMenuContext &&
    actionMenuContext.station?.id === station.id &&
    actionMenuContext.toolGridItem === null &&
    actionMenuContext.term === null

  const handleStationClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setActionMenuContext({
      stationGridItem: `station-grid-item-${station.row}-${station.column}`,
      station,
      toolGridItem: null,
      tool: null,
      stationListId: null,
      term: null,
    })
  }

  const handleToolGridItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    gridItemKey: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    const { row, column } = orderingHelper.getRowColumnByKey(gridItemKey)

    const tool = orderingHelper.findToolByRowColumn(station, row, column)

    if (!tool) {
      setActionMenuContext({
        stationGridItem: `station-grid-item-${station.row}-${station.column}`,
        station,
        toolGridItem: gridItemKey,
        tool: null,
        stationListId: null,
        term: null,
      })
    }
  }

  const findToolByRowColumn = (row: number, column: number): IToolData | null => {
    if (station) {
      return station.tools?.find((tool) => tool.row === row && tool.column === column)
    }

    return null
  }

  const isToolGridItemActive = (gridItemKey: string): boolean =>
    !!actionMenuContext &&
    actionMenuContext.toolGridItem &&
    formatActionMenuGridKey(actionMenuContext.toolGridItem) ===
      formatActionMenuGridKey(gridItemKey) &&
    actionMenuContext.tool === null

  return (
    <Box
      sx={stationStyle(
        study.row_count,
        screenHeight,
        isStationActive(),
        isPinnedStationDrawerOpen,
        viewType,
        study.stations.filter((stationObject) => stationObject.is_pinned).length,
        topHeaderHeight,
        station.is_pinned,
      )}
      onClick={handleStationClick}
    >
      <StationHeader station={station} clipboard={clipboard} />

      {[...Array(station.row_count + 1)].map((_, rowIndex) => (
        <Grid
          key={`station-${rowIndex}`}
          container
          wrap="nowrap"
          columnSpacing={0}
          padding="0"
          justifyContent="center"
        >
          {[...Array(station.column_count + 1)].map((_, columnIndex) => {
            const toolKey = `${station.id}-tool-droppable${
              viewType === 'pin' ? '-pin' : ''
            }-${rowIndex}-${columnIndex}`

            const tool = findToolByRowColumn(rowIndex, columnIndex)

            if (tool) {
              return (
                <Grid
                  key={toolKey}
                  item
                  sx={toolGridItemStyle(
                    isToolGridItemActive(toolKey),
                    screenWidth,
                    station.column_count,
                    study.column_count,
                    !!tool,
                    columnIndex,
                    isPinnedStationDrawerOpen,
                    viewType,
                  )}
                  onClick={(event) => handleToolGridItemClick(event, toolKey)}
                >
                  <Paper className="MuiPaper-tool">
                    <Tool
                      study={study}
                      tool={tool}
                      station={station}
                      mutateStudy={mutateStudy}
                      clipboard={clipboard}
                      viewType={viewType}
                    />
                  </Paper>
                </Grid>
              )
            }
            return (
              <Grid
                key={toolKey}
                item
                sx={toolGridItemStyle(
                  isToolGridItemActive(toolKey),
                  screenWidth,
                  station.column_count,
                  study.column_count,
                  !!tool,
                  columnIndex,
                  isPinnedStationDrawerOpen,
                  viewType,
                )}
                onClick={(event) => handleToolGridItemClick(event, toolKey)}
              >
                <Paper className="MuiPaper-tool" />
              </Grid>
            )
          })}
        </Grid>
      ))}
    </Box>
  )
}

export default React.memo(Station)
