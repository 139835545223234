import { t } from '@lingui/macro'
import { Button, Typography, Box } from '@mui/material'

import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'
import { SelectElement } from 'react-hook-form-mui'
import { KeyedMutator } from 'swr'

import usePersonalityTools from 'hooks/usePersonalityTools'
import useStudyTools from 'hooks/useStudyTools'

import { toolMutate } from 'hooks/studyMutations'

import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

import paths from 'utils/paths'

import StudyRepository from 'repositories/StudyRepository'
import AIFormCheckboxes from './AIFormCheckboxes'

type FormValues = {
  aiContextLists: string[]
  aiPersonality: string
  aiJournal: string
}

type AIFormProps = {
  study: IStudyData | null
  tool: IToolData
  onModalClose: () => void
  mutateStudy: KeyedMutator<IStudyData>
}

export default function AIForm(props: AIFormProps) {
  const { study, tool, onModalClose, mutateStudy } = props

  const { data: studyTools } = useStudyTools(paths.backend.study.tools(study.id))

  const { data: personalityTools } = usePersonalityTools(
    paths.backend.study.tool.toolsPersonalities,
  )

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      aiContextLists: tool.ai_context_lists ?? [],
      aiPersonality: tool.ai_personality ?? '0',
      aiJournal: tool.ai_journal ?? '0',
    },
  })

  const handleClose = () => {
    onModalClose()
  }

  const onValid: SubmitHandler<FormValues> = (data) => {
    const newTool = {
      ...tool,
      ai_context_lists: data.aiContextLists,
      ai_personality: data.aiPersonality === '0' ? '' : data.aiPersonality,
      ai_journal: data.aiJournal === '0' ? '' : data.aiJournal,
    }

    StudyRepository.updateTool(tool.id, newTool)
      .then((data) => {
        mutateStudy(toolMutate(tool.station, tool.id, data), false)
          .then(() => {
            onModalClose()
          })
          .catch((err) => console.error(err))
      })
      .catch((err) => console.error(err))
  }

  const onInvalid: SubmitErrorHandler<FormValues> = (errors) => {
    console.error(errors)
  }

  const getOptions = () => {
    if (studyTools) {
      return studyTools
        .filter((studyTool) => studyTool.title)
        .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
        .map((studyTool) => ({
          id: studyTool.id,
          title: studyTool.title,
        }))
    }

    return []
  }

  const getOptionsSelect1 = () => {
    let list = [
      {
        id: '0',
        title: t`Select an option`,
      },
    ]

    if (personalityTools) {
      const opts = personalityTools
        .filter((personalityTool) => personalityTool.title)
        .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
        .map((personalityTool) => ({
          id: personalityTool.id,
          title: personalityTool.title,
        }))

      list = [...list, ...opts]
    }

    return list
  }

  const getOptionsSelect2 = () => {
    let list = [
      {
        id: '0',
        title: t`Select an option`,
      },
    ]

    if (studyTools) {
      const opts = studyTools
        .filter((studyTool) => studyTool.title)
        .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
        .map((studyTool) => ({
          id: studyTool.id,
          title: studyTool.title,
        }))

      list = [...list, ...opts]
    }

    return list
  }

  const options = getOptions()
  const optionsSelect1 = getOptionsSelect1()
  const optionsSelect2 = getOptionsSelect2()

  return (
    <Box display="flex" flexDirection="column">
      <Typography color="text" variant="h1" fontWeight={400}>
        {t`Configure your AI Colleague`}
      </Typography>

      <form noValidate>
        <Box mt={2}>
          <AIFormCheckboxes
            control={control}
            label={t`Choose what content to share with the AI`}
            name="aiContextLists"
            options={options}
          />
        </Box>

        <Box mt={2}>
          <SelectElement
            control={control}
            fullWidth
            label={t`Enhance your colleagues responses by giving it a personality`}
            labelKey="title"
            name="aiPersonality"
            options={optionsSelect1}
          />
        </Box>

        <Box mt={2}>
          <SelectElement
            control={control}
            fullWidth
            label={t`Select a list where to keep a copy of the answers (optional)`}
            labelKey="title"
            name="aiJournal"
            options={optionsSelect2}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" margin="48px 0 16px 0" gap="8px">
          <Button onClick={() => handleClose()}>{t`Close`}</Button>

          <Button onClick={handleSubmit(onValid, onInvalid)} type="submit" variant="contained">
            {t`Save`}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
