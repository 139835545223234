import { lazy, Suspense, useCallback, useEffect, useState, MouseEvent } from 'react'

import { Location, NavigateFunction, useLocation, useNavigate } from 'react-router-dom'
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  Help,
  InfoOutlined as InfoOutlinedIcon,
  InsertCommentOutlined as InsertCommentOutlinedIcon,
  Logout as LogoutIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
} from '@mui/icons-material'
import { t } from '@lingui/macro'
import MenuIcon from '@mui/icons-material/Menu'
import ClipboardDrawer from 'components/sts-app-bar/ClipboardDrawer'
import LanguageSelector from 'components/sts-app-bar/LanguageSelector'
import { colors } from 'shared/theme'
import { useDrawerComponentContext } from 'contexts/DrawerComponentContext'
import paths from 'utils/paths'
import ClipboardContext, { IClipboard } from 'contexts/ClipboardContext'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

const About = lazy(() => import('./About'))

function STSAppBar() {
  const location: Location = useLocation()
  const navigate: NavigateFunction = useNavigate()

  const [clipboardDrawerOpen, setClipboardDrawerOpen] = useState(false)
  const [title, setTitle] = useState(t`STS42`)
  const [aboutOpen, setAboutOpen] = useState(false)
  const [userAnchorEl, setUserAnchorEl] = useState<HTMLElement | null>(null)
  const { drawerOpen, setDrawerOpen } = useDrawerComponentContext()
  const { pasteMenuCount } = useStudyStatesContext()

  useEffect(() => {
    let title = t`STS42`
    if (location.pathname === '/') {
      title += ` - ${t`Studies`}`
    } else if (location.pathname.includes('/teams/')) {
      title += ` - ${t`Team`}`
    } else if (location.pathname.startsWith('/study/')) {
      title += ` - ${t`Study`}`
    } else if (
      location.pathname.startsWith('/account-edit/me') ||
      location.pathname.startsWith('/password-edit/me')
    ) {
      title += ` - ${t`Profile`}`
    }
    document.title = title
    setTitle(title)
  }, [location.pathname])

  const handleOpenAbout = () => {
    setUserAnchorEl(null)
    setAboutOpen(true)
  }

  const handleCloseAbout = () => {
    setAboutOpen(false)
  }

  const handleOpenClipboardDrawer = () => {
    setClipboardDrawerOpen(true)
  }

  const handleCloseClipboardDrawer = useCallback(() => {
    setClipboardDrawerOpen(false)
  }, [])

  const handleOpenUserMenu = (e: MouseEvent<HTMLElement>) => {
    setUserAnchorEl(e.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setUserAnchorEl(null)
  }

  const handleAccountEditClick = () => {
    setUserAnchorEl(null)
    navigate('/account-edit/me')
  }

  const handleLogoutClick = () => {
    setUserAnchorEl(null)
    navigate('/auth/logout')
  }

  document.title = title

  const isRouteWithSideMenuDrawer = () => {
    const { pathname } = location

    if (pathname)
      return (
        pathname === paths.frontend.home ||
        pathname.includes(paths.frontend.projects) ||
        pathname.includes(paths.frontend.teams()) ||
        pathname.includes(paths.frontend.study())
      )

    return false
  }

  return (
    <AppBar position={isRouteWithSideMenuDrawer() ? 'fixed' : 'static'}>
      <Toolbar sx={{ height: '100%', justifyContent: 'space-between' }}>
        <Box
          display="flex"
          component="a"
          href={paths.frontend.home}
          alignItems="center"
          sx={{ textDecoration: 'none' }}
        >
          <Box
            component="img"
            sx={{
              height: '30px',
              width: 'auto',
            }}
            src="/logo80-white.png"
          />

          <Box display="flex" margin="0 0 0 8px">
            <Typography variant="h6" color="white">
              STS42
            </Typography>
          </Box>
        </Box>

        <Box display={{ xs: 'flex', md: 'none' }} alignItems="center">
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            size="medium"
            sx={{ color: colors.white.A500 }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Box display={{ xs: 'none', md: 'flex' }} alignItems="center">
          <Tooltip title={t`Open clipboard panel`} disableInteractive>
            <IconButton color="inherit" onClick={handleOpenClipboardDrawer} size="small">
              <Badge badgeContent={pasteMenuCount} color="warning">
                <ContentPasteSearchIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <LanguageSelector aria-label="language" />
          <a
            href={t`https://documentation.sts42.com`}
            style={{ color: 'inherit', textDecoration: 'none' }}
            target="documentation"
            aria-label="help"
          >
            <Tooltip title={t`Help`} disableInteractive>
              <IconButton color="inherit" size="small">
                <Help fontSize="medium" />
              </IconButton>
            </Tooltip>
          </a>
          <Box>
            <Tooltip title={t`Open user menu`} disableInteractive>
              <IconButton
                aria-controls="user-menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenUserMenu}
                sx={{ ml: 2, px: 0 }}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={userAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id="user-menu-appbar"
              keepMounted
              onClose={handleCloseUserMenu}
              open={Boolean(userAnchorEl)}
              sx={{ mt: '30px' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {localStorage.getItem('user_name') ? (
                <MenuItem onClick={handleAccountEditClick} sx={{ py: '4px' }}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>
                      {localStorage.getItem('user_name')}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              ) : (
                <span />
              )}
              <Divider sx={{ my: '8px' }} />

              <MenuItem
                component="a"
                href="https://forms.clickup.com/f/dgbxq-7640/OC5XD2091JDD91JT8M"
                target="_blank"
                onClick={handleCloseUserMenu}
                sx={{ py: '4px', color: 'inherit', textDecoration: 'none' }}
              >
                <ListItemIcon>
                  <InsertCommentOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Feedback Form`}</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem
                component="a"
                href={t`https://documentation.sts42.com`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleCloseUserMenu}
                sx={{ py: '4px', color: 'inherit', textDecoration: 'none' }}
              >
                <ListItemIcon>
                  <Help fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`User documentation`}</Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenAbout} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <InfoOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`About STS42`}</Typography>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogoutClick} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Logout`}</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>

      <ClipboardContext.Consumer>
        {(clipboard: IClipboard) => (
          <ClipboardDrawer
            onClose={handleCloseClipboardDrawer}
            open={clipboardDrawerOpen}
            clipboard={clipboard}
          />
        )}
      </ClipboardContext.Consumer>

      {aboutOpen && (
        <Suspense fallback={null}>
          <About open={aboutOpen} onClose={handleCloseAbout} />
        </Suspense>
      )}
    </AppBar>
  )
}

export default STSAppBar
