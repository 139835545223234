import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import ITermData, {
  ITermMoveData,
  ITermUploadProgressResponseData,
  ITermUploadStartData,
  ITermUploadStartResponseData,
} from 'types/ITermData'
import paths from 'utils/paths'
import IStudyData, { IPasteDataInterface } from 'types/IStudyData'
import IToolData from 'types/IToolData'

const speechToTextTerm = (toolId: string, file: Blob): Promise<AxiosResponse<IToolData>> => {
  const formData = new FormData()
  formData.append('tool', toolId)
  formData.append('audio_file', file)
  return axiosInstance.post<IToolData, AxiosResponse<IToolData>>(
    paths.backend.study.term.termsSpeechToText,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}

const fileUploadStartTerm = (
  id: string,
  fileInfo: ITermUploadStartData,
): Promise<AxiosResponse<ITermUploadStartResponseData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<ITermUploadStartResponseData>>(
    paths.backend.study.term.termsFileUploadStart(id),
    fileInfo,
  )

const fileUploadProgressTerm = (
  uploadUrl: string,
  fileFormData: FormData,
): Promise<AxiosResponse<ITermUploadProgressResponseData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<ITermUploadProgressResponseData>>(
    uploadUrl,
    fileFormData,
  )

const fileUploadFinishTerm = (id: string, fileId: string): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<ITermData>>(
    paths.backend.study.term.termsFileUploadFinish(id, fileId),
    { file_id: fileId },
  )

const pasteTerms = (
  toolId: string,
  pasteData: IPasteDataInterface,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.tool.toolsPasteTerm(toolId),
    pasteData,
  )

const deleteTerm = (id: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(paths.backend.study.term.terms(id))

const moveTerm = (id: string, data: ITermMoveData): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.term.termsMove(id),
    data,
  )

const addTerm = (term: ITermData): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.post<ITermData, AxiosResponse<ITermData>>(paths.backend.study.term.terms(), term)

const update = (
  id: string,
  data: ITermData,
  options: { signal?: AbortSignal } = {},
): Promise<AxiosResponse<ITermData>> =>
  axiosInstance.put<ITermData, AxiosResponse<ITermData>>(paths.backend.study.term.terms(id), data, {
    ...options,
  })

const TermDataService = {
  speechToTextTerm,
  pasteTerms,
  deleteTerm,
  fileUploadStartTerm,
  fileUploadProgressTerm,
  fileUploadFinishTerm,
  moveTerm,
  addTerm,
  update,
}

export default TermDataService
