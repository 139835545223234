import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'

import { Control, Controller, Path } from 'react-hook-form'

type Option = {
  id: string
  title: string
}

type AIFormCheckboxes<T> = {
  control: Control<T>
  label: string
  name: Path<T>
  options: Option[]
}

const AIFormCheckboxes = <T extends object>({
  control,
  label,
  name,
  options,
}: AIFormCheckboxes<T>) => {
  function handleSelect(idChecked: string, values: string[] = []) {
    const newValues = values.includes(idChecked)
      ? values.filter((id) => id !== idChecked)
      : [...values, idChecked]

    return newValues
  }

  return (
    <FormControl component="fieldset" fullWidth>
      <FormLabel component="legend">{label}</FormLabel>

      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormGroup>
            {options.map((option) => {
              const { id, title } = option

              return (
                <FormControlLabel
                  key={id}
                  label={title}
                  onClick={() => field.onChange(handleSelect(id, field.value))}
                  control={<Checkbox checked={(field.value as string[]).includes(id)} />}
                />
              )
            })}
          </FormGroup>
        )}
      />
    </FormControl>
  )
}

export default AIFormCheckboxes
