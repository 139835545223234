import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Alert, Box, Button, CssBaseline, Container, Grid, Typography } from '@mui/material'

import { t } from '@lingui/macro'
import AccountDataService from '../../data-services/AccountDataService'
import FormTextField from '../../components/control/FormTextField'

type FormPasswordEditFields = {
  password: string
  confirmPassword: string
}

export default function PasswordEdit(): JSX.Element {
  console.log('Load Password Edit Component')

  const navigate: NavigateFunction = useNavigate()
  const [saveError, setSaveError] = useState<string>('')

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .label(t`The new password`)
      .required()
      .max(100)
      .min(8),
    confirmPassword: yup
      .string()
      .label(t`The confirm password`)
      .required()
      .oneOf([yup.ref('password')], t`Passwords does not match`),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormPasswordEditFields>({
    resolver: yupResolver(formSchema),
  })

  const handleFormSubmit = (data: FormPasswordEditFields): void => {
    setSaveError('')

    AccountDataService.changePassword(data.password)
      .then(() => {
        console.log('Successful password modification')
        navigate('/')
      })
      .catch((err) => {
        console.log('ERROR: An error occurred during registration', err, err.response)
        setSaveError(t`An error occurred during registration, please try again.`)
      })
  }

  const handlePasswordEditClick = (): void => {
    navigate('/account-edit/me')
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Typography component="h1" variant="h6">
          {t`Change your password`}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{ mt: 5, width: '100%' }}
        >
          <FormTextField
            errorText={errors.password?.message}
            label={t`New Password`}
            margin="normal"
            registerReturn={register('password')}
            type="password"
          />
          <Box sx={{ color: '#999', fontSize: '11px' }}>
            {t`Your password must contain at least ${8} characters.`}
          </Box>
          <FormTextField
            errorText={errors.confirmPassword?.message}
            label={t`Confirm Password`}
            margin="normal"
            registerReturn={register('confirmPassword')}
            type="password"
          />
          <Box sx={{ color: '#999', fontSize: '11px' }}>
            {t`Enter the same password as before, for verification.`}
          </Box>
          {saveError !== '' ? (
            <Alert severity="error" sx={{ mt: 2 }}>
              {saveError}
            </Alert>
          ) : (
            <span />
          )}
          <Button color="primary" fullWidth sx={{ mb: 2, mt: 3 }} type="submit" variant="contained">
            {t`Save`}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                onClick={handlePasswordEditClick}
                size="small"
                sx={{ fontSize: '14px', textTransform: 'none' }}
                variant="text"
              >
                {t`Cancel`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  )
}
