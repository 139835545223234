import { AxiosResponse } from 'axios'
import axiosInstance from 'utils/axios'
import IToolData from 'types/IToolData'
import paths from 'utils/paths'
import IStudyData, {
  IPasteDataInterface,
  IStudyGridItemData,
  IStudyImportData,
} from 'types/IStudyData'
import IStationData from 'types/IStationData'

const rootPath = '/studies/tools'

const importTermFromFileTool = (
  id: string,
  importData: IStudyImportData,
): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(
    paths.backend.study.tool.toolsImportTermFromFile(id),
    importData,
  )

const pasteTools = (
  stationId: string,
  pasteData: IPasteDataInterface,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.station.stationsPasteTool(stationId),
    pasteData,
  )

const moveToolGridItem = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStudyData>> =>
  axiosInstance.post<IStudyData, AxiosResponse<IStudyData>>(
    paths.backend.study.tool.toolsMove(id),
    gridItemData,
  )

const pushTool = (
  id: string,
  gridItemData: IStudyGridItemData,
): Promise<AxiosResponse<IStationData>> =>
  axiosInstance.post<IStationData, AxiosResponse<IStationData>>(
    paths.backend.study.tool.toolsPush(id),
    gridItemData,
  )

const deleteToolTerms = (id: string): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(
    paths.backend.study.tool.toolsDeleteTerms(id),
  )

const processTool = (id: string, data: IToolData): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(
    paths.backend.study.tool.toolsProcess(id),
    data,
  )

const addTool = (data: IToolData): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<IToolData, AxiosResponse<IToolData>>(paths.backend.study.tool.tools(), data)

const get = (id: string): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.get<unknown, AxiosResponse<IToolData>>(`${rootPath}/${id}`)

const deleteTool = (id: string): Promise<AxiosResponse<unknown>> =>
  axiosInstance.delete<unknown, AxiosResponse<unknown>>(paths.backend.study.tool.tools(id))

const update = (
  id: string,
  data: IToolData,
  options: { signal?: AbortSignal } = {},
): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.put<IToolData, AxiosResponse<IToolData>>(`${rootPath}/${id}/`, data, {
    ...options,
  })

const removeContext = (id: string, context: string): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<unknown, AxiosResponse<IToolData>>(
    `${rootPath}/${id}/remove-context/${context}/`,
  )

const addContext = (id: string, context: string): Promise<AxiosResponse<IToolData>> =>
  axiosInstance.post<unknown, AxiosResponse<IToolData>>(`${rootPath}/${id}/add-context/${context}/`)

const ToolDataService = {
  importTermFromFileTool,
  pasteTools,
  deleteToolTerms,
  moveToolGridItem,
  pushTool,
  processTool,
  addTool,
  get,
  deleteTool,
  update,
  removeContext,
  addContext,
}

export default ToolDataService
