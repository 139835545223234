import React from 'react'
import { Box, Tooltip } from '@mui/material'
import { t } from '@lingui/macro'
import { IClipboard, IClipboardItemState } from 'contexts/ClipboardContext'
import IStationData from 'types/IStationData'
import floatingActionMenuStyle from 'shared/components/floatingActionMenuStyle'
import { useErrorContext } from 'contexts/ErrorContext'
import StudyRepository from 'repositories/StudyRepository'
import Checkbox from 'components/control/Checkbox'

type FloatingActionMenuStationProps = {
  station?: IStationData
  clipboard: IClipboard
}

export default function FloatingActionMenuStation({
  station,
  clipboard,
}: FloatingActionMenuStationProps): JSX.Element {
  const { handleAsyncError } = useErrorContext()
  StudyRepository.initialize(handleAsyncError)

  const cbStationState: IClipboardItemState<IStationData> | null = clipboard.stations.getItemState(
    station?.id,
  )
  const isCopiedToClipboard: boolean = cbStationState !== null && cbStationState.action === 'copy'
  const isCutToClipboard: boolean = cbStationState !== null && cbStationState.action === 'cut'

  const handleCopyCheckboxClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    if (station) {
      if (!isCopiedToClipboard && !isCutToClipboard) {
        clipboard.stations.addItem('copy', station)
      } else {
        clipboard.stations.removeItem(station.id)
      }
    }
  }

  return (
    <>
      <Box position="relative">
        <Box sx={floatingActionMenuStyle(station?.is_pinned)}>
          {station && (
            <Box display="flex" alignItems="flex-start" justifyContent="center">
              <Tooltip
                title={
                  isCopiedToClipboard || isCutToClipboard
                    ? t`Remove Station from clipboard`
                    : t`Add Station to clipboard`
                }
                disableInteractive
              >
                <Box sx={{ opacity: 0.4 }}>
                  <Checkbox
                    onClick={(e) => handleCopyCheckboxClick(e)}
                    checked={isCopiedToClipboard || isCutToClipboard}
                    color="primary"
                    sx={{ zIndex: '1' }}
                  />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}
