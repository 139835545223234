import { createContext, useState, useContext, useMemo } from 'react'

interface HeaderHeightContextType {
  height: number
  setHeight: React.Dispatch<React.SetStateAction<number>>
}

const HeaderHeightContext = createContext<HeaderHeightContextType | undefined>(undefined)

export const useHeaderHeight = () => {
  const context = useContext(HeaderHeightContext)

  if (!context) {
    throw new Error('useHeaderHeightContext must be used within a HeaderHeightContextProvider')
  }

  return context
}

export const HeaderHeightContextProvider: React.FC = ({ children }) => {
  const [height, setHeight] = useState(0)

  const value = useMemo(
    () => ({
      height,
      setHeight,
    }),
    [height],
  )

  return <HeaderHeightContext.Provider value={value}>{children}</HeaderHeightContext.Provider>
}
