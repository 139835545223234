import { memo, MouseEvent } from 'react'

import { Box } from '@mui/material'
import { Draggable } from 'react-beautiful-dnd'
import { IClipboard } from 'contexts/ClipboardContext'
import IToolData from 'types/IToolData'
import ITermData from 'types/ITermData'
import termStyle from 'shared/components/termStyle'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import FloatingActionMenuTerm from 'components/floating-action-menu/FloatingActionMenuTerm'
import IStationData from 'types/IStationData'

import TermInput from './TermInput'

type TermProps = {
  station: IStationData
  tool: IToolData
  term: ITermData
  termIndex: number
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function Term({ station, tool, term, termIndex, clipboard, viewType }: TermProps): JSX.Element {
  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()

  const termKey = `tool${viewType === 'pin' ? '-pin' : ''}-${term.tool}-term-${term.id}`

  const isTermActive = (): boolean => !!actionMenuContext && actionMenuContext.term?.id === term.id

  const handleTermClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActionMenuContext({
      stationGridItem: null,
      station,
      toolGridItem: null,
      tool,
      stationListId: null,
      term,
    })
  }

  return (
    <Draggable draggableId={termKey} index={termIndex} key={termKey}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={termStyle(isTermActive(), snapshot.isDragging)}
          onClick={handleTermClick}
        >
          <Box display="flex">
            <Box className="drag-handle" />
            <Box flexGrow="1" padding="8px 16px" className="MuiTerm-container">
              <Box display="flex">
                <Box
                  display="flex"
                  width="100%"
                  alignItems="center"
                  sx={{
                    background: 'transparent',
                    padding: '0',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  <TermInput term={term} />
                </Box>

                <FloatingActionMenuTerm clipboard={clipboard} station={station} term={term} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  )
}

export default memo(Term)
