import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { MutatorCallback } from 'swr'

import { Box } from '@mui/material'

import DialogComponent from 'components/dialog/DialogComponent'
import StudyForm from 'components/form/StudyForm'

import IStudyData from 'types/IStudyData'

type StudyDialogProps = {
  projectId: string | null
  study: IStudyData | null
  open: boolean
  onModalClose: () => void
  mutator: MutatorCallback
  setSubmitting?: Dispatch<SetStateAction<boolean>>
}
export default function StudyDialog(props: StudyDialogProps) {
  const { projectId, study, open, onModalClose, mutator, setSubmitting } = props
  const dialogRef = useRef(null)
  const [isLoading, setLoading] = useState(false)

  return (
    <DialogComponent
      open={open}
      onModalClose={onModalClose}
      scroll="paper"
      ref={dialogRef}
      sx={{ margin: 0 }}
      isLoading={isLoading}
    >
      <Box sx={{ width: { xs: 'auto', md: '500px' } }}>
        {projectId && (
          <StudyForm
            isLoading={isLoading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            study={study}
            projectId={projectId}
            mutator={mutator}
            setSubmitting={setSubmitting}
          />
        )}
      </Box>
    </DialogComponent>
  )
}
