import { createTheme } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { PaletteOptions } from '@mui/material/styles/createPalette'

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true
    sm: true
    md: true
    lg: true
    xl: true
    mobile: true
  }
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 3000,
    mobile: 900,
  },
}
type BreakpointValues = keyof typeof breakpoints.values
const mediaQueryMin: Record<BreakpointValues, string> = {} as Record<BreakpointValues, string>
const mediaQueryMax: Record<BreakpointValues, string> = {} as Record<BreakpointValues, string>
Object.keys(breakpoints.values).forEach((key) => {
  mediaQueryMin[key as BreakpointValues] = `@media (min-width: ${
    breakpoints.values[key as BreakpointValues]
  }px)`

  mediaQueryMax[key as BreakpointValues] = `@media (max-width: ${
    breakpoints.values[key as BreakpointValues]
  }px)`
})

declare module '@mui/material/styles/createPalette' {
  interface PaletteOptions {
    term: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
      error: {
        background: string
        color: string
      }
      errorDetails: {
        background: string
      }
    }
    tool: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
    }
    station: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
      pin: {
        background: string
      }
    }
    gridItem: {
      main: {
        border: string
      }
      active: {
        border: string
      }
    }
  }

  interface Palette {
    term: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
      error: {
        background: string
        color: string
      }
      errorDetails: {
        background: string
      }
    }
    tool: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
    }
    station: {
      main: {
        background: string
        border: string
      }
      drag: {
        background: string
        border: string
      }
      pin: {
        background: string
      }
    }
    gridItem: {
      main: {
        border: string
      }
      active: {
        border: string
      }
    }
  }
}

declare module '@mui/material/styles' {
  interface ThemeOptions {
    appBarHeight: string
    mediaQueryMin: Record<BreakpointValues, string>
    mediaQueryMax: Record<BreakpointValues, string>
    sideMenuDesktopWidth: string
    actionMenuHeight: string
    studyHeaderTitleHeight: string
  }
  interface Theme {
    appBarHeight: string
    mediaQueryMin: Record<BreakpointValues, string>
    mediaQueryMax: Record<BreakpointValues, string>
    sideMenuDesktopWidth: string
    actionMenuHeight: string
    studyHeaderTitleHeight: string
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subtitle2Responsive: true
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    subtitle2Responsive: Record<string, unknown>
  }

  interface TypographyOptions {
    subtitle2Responsive: Record<string, unknown>
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    containedLightBlue: true
    containedDarkBlue: true
    containedWhite: true
    containedRed: true
    text: true
    textBlock: true
  }
}

export const colors = {
  lightBlue: {
    A300: '#DEE9F8', // Button light blue
    A400: '#EDF0F7', // More action menu , Station
    A500: '#f7f9ff', // Study Card
    A550: '#F2F4FA', // Chip
    A600: '#C4C7D0', // Projects
    A700: '#F7FCFF', // Terms
  },

  blue: {
    A300: '#1F58A3', // Term hyperlink
    A400: 'rgb(3, 169, 244)', // Info
    A500: '#276FCE', // Primary and Action and station
    A700: '#17457f', // Button dark blue hover
    A800: '#0E2A4D', // Button dark blue, station
  },
  green: {
    A300: 'rgb(232, 245, 233)', // Station
    A400: 'rgb(30, 70, 32)',
    A500: 'rgb(76, 175, 80)', // Success
  },
  red: {
    A100: '#ffcdd2',
    A200: '#ef9a9a',
    A500: '#B3261E',
    A600: 'rgb(181, 63, 81)',
  },
  black: {
    A500: '#45494F',
    A600: '#1B1C20',
  },
  gray: {
    A300: '#49454F', // Term hyperlink
    A350: '#B6571', // Term meta
    A400: '#EFF1F7',
    A500: 'rgba(0, 0, 0, 0.54)',
    A600: '#75747E',
    A700: '#E0E4E9', // Input search
  },
  white: {
    A500: '#FFFFFF',
  },
}

const overflowTextStyle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}

// Create a theme instance.
const theme = createTheme({
  spacing: 8,
  appBarHeight: '64px',
  mediaQueryMin,
  mediaQueryMax,
  sideMenuDesktopWidth: '15%',
  studyHeaderTitleHeight: '75px',
  actionMenuHeight: '100%',
  palette: {
    success: {
      main: colors.green.A500,
      secondary: colors.green.A400,
    },
    info: {
      main: colors.blue.A400,
      secondary: colors.blue.A300,
    },
    primary: {
      main: colors.blue.A500,
    },
    secondary: {
      main: colors.gray.A500,
      secondary: colors.gray.A400,
    },
    error: {
      main: colors.red.A500,
    },
    warning: {
      main: colors.red.A600,
    },
    text: {
      primary: colors.black.A500,
      secondary: colors.blue.A800,
    },
    tool: {
      main: { background: colors.white.A500, border: colors.lightBlue.A600 },
      drag: { background: colors.green.A300, border: colors.blue.A500 },
    },
    term: {
      main: { background: colors.lightBlue.A700, border: colors.lightBlue.A600 },
      drag: { background: colors.green.A300, border: colors.blue.A500 },
      error: { background: colors.red.A100, color: colors.red.A500 },
      errorDetails: { background: colors.red.A200 },
    },
    station: {
      main: { background: colors.lightBlue.A400, border: colors.lightBlue.A600 },
      drag: { background: colors.green.A300, border: colors.blue.A500 },
      pin: { background: colors.green.A300 },
    },
    gridItem: {
      main: {
        border: colors.lightBlue.A600,
      },
      active: {
        border: colors.blue.A500,
      },
    },
  } as PaletteOptions,
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '24px',
      ...overflowTextStyle,
    },
    h3: {
      fontSize: '19px',
      ...overflowTextStyle,
    },
    h4: {
      fontSize: '18px',
      ...overflowTextStyle,
    },
    h5: {
      fontSize: '16px',
      ...overflowTextStyle,
    },
    h6: {
      fontSize: '15px',
      ...overflowTextStyle,
    },
    body1: {
      fontSize: '14px',
      ...overflowTextStyle,
    },
    subtitle1: {
      fontSize: '14px',
      color: colors.gray.A500,
      ...overflowTextStyle,
    },
    subtitle2: {
      fontSize: '12px',
      color: colors.gray.A500,
      ...overflowTextStyle,
    },
    subtitle2Responsive: {
      fontFamily: 'Roboto, Arial, sans-serif',
      [mediaQueryMin.xs]: {
        fontSize: '14px',
      },
      [mediaQueryMin.md]: {
        fontSize: '16px',
      },
      ...overflowTextStyle,
    },
  } as TypographyOptions,
  breakpoints,
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: theme.appBarHeight,
          boxShadow: 'unset',
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          disableInteractive: true,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: () => ({
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: () => ({
          '&.MuiPaper-elevation1': {
            background: colors.lightBlue.A500,
            borderRadius: '12px',
            border: `1px solid ${colors.lightBlue.A600}`,
            boxShadow: 'unset',
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: () => ({
          '&.MuiInput-test': {
            '& .MuiInputBase-root': {
              background: 'transparent',
              padding: '0',
            },
            '& fieldset': {
              border: 'unset',
            },
          },
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          padding: 0,
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          textTransform: 'unset',
        }),
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            color: colors.white.A500,
            backgroundColor: colors.blue.A500,
            borderRadius: '100px',
            textTransform: 'unset',
            minHeight: '40px',
          },
        },
        {
          props: { variant: 'containedLightBlue' },
          style: {
            color: colors.blue.A800,
            backgroundColor: colors.lightBlue.A300,
            borderRadius: '100px',
            minHeight: '40px',
            '& .MuiButton-startIcon, .MuiButton-endIcon': {
              color: colors.blue.A500,
            },
          },
        },
        {
          props: { variant: 'containedDarkBlue' },
          style: {
            color: colors.white.A500,
            backgroundColor: colors.blue.A800,
            borderRadius: '100px',
            minHeight: '40px',
            '&:hover': {
              backgroundColor: colors.blue.A700,
            },
          },
        },
        {
          props: { variant: 'containedWhite' },
          style: {
            color: colors.blue.A500,
            backgroundColor: colors.white.A500,
            borderRadius: '100px',
            textTransform: 'unset',
            border: `1px solid ${colors.black.A500}`,
            minHeight: '40px',
          },
        },
        {
          props: { variant: 'containedRed' },
          style: {
            color: colors.white.A500,
            backgroundColor: colors.red.A500,
            borderRadius: '100px',
            textTransform: 'unset',
            border: 'none',
            minHeight: '40px',
            '&:hover': {
              backgroundColor: colors.red.A600,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            padding: '1px !important',
            justifyContent: 'flex-start',
            '& .MuiTypography-active': {
              color: colors.blue.A500,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              '& .MuiTypography-root, .MuiButton-startIcon svg': {
                color: colors.blue.A500,
              },
              '& .MuiTypography-danger, .MuiButton-startIcon-danger': {
                color: `${colors.red.A500} !important`,
              },
            },
          },
        },
        {
          props: { variant: 'textBlock' },
          style: {
            display: 'block',
            padding: '1px !important',
            justifyContent: 'flex-start',
            '& .MuiButton-startIcon': {
              margin: 0,
              color: colors.black.A500,
            },
            '& .MuiButton-startIcon-fixedHeight': {
              height: '28px',
            },
            '& .MuiTypography-active, .MuiButton-startIcon-active': {
              color: colors.blue.A500,
            },
            '& .MuiTypography-danger-active, .MuiButton-startIcon-danger-active': {
              color: `${colors.red.A500} !important`,
            },

            '&:hover': {
              backgroundColor: 'transparent',
              '& .MuiTypography-root, .MuiButton-startIcon': {
                color: colors.blue.A500,
              },
              '& .MuiTypography-danger, .MuiButton-startIcon-danger': {
                color: colors.red.A500,
              },
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: () => ({
          '& .MuiInputBase-root': {
            padding: '8px 16px 8px 8px !important',
            borderRadius: '25px',
            height: '40px',
            background: colors.gray.A700,
          },

          '& fieldset': {
            border: 'unset',
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          background: colors.lightBlue.A550,
          border: 'unset',
          marginTop: '8px',
          borderRadius: '8px',
          '&:hover': {
            background: `${colors.lightBlue.A550}`,
            opacity: 0.8,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '28px',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: () => ({
          '&.MuiSelect-text': {
            color: '#fff',
            '& .MuiSelect-select': {
              background: 'transparent !important',
            },
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: () => ({
          '&.MuiAlert-fullwidth .MuiAlert-message': {
            width: '100%',
          },
        }),
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
})

export default theme
