import { useCallback } from 'react'

import { Box, FormControl, MenuItem, Typography } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { activateInitialLanguage, getInitialLanguage, setInitialLanguage } from 'utils/i18n'

function LanguageSelector() {
  const handleSelectChange = useCallback((e: SelectChangeEvent): void => {
    setInitialLanguage(e.target.value)
    activateInitialLanguage()
  }, [])

  return (
    <Box sx={{ ml: 2 }}>
      <FormControl variant="standard">
        <Select
          disableUnderline
          onChange={handleSelectChange}
          value={getInitialLanguage()}
          variant="standard"
          className="MuiSelect-text"
        >
          <MenuItem sx={{ py: '4px' }} value="en">
            <Typography variant="body1" color="text">
              English
            </Typography>
          </MenuItem>
          <MenuItem sx={{ py: '4px' }} value="fr">
            <Typography variant="body1" color="text">
              Français
            </Typography>
          </MenuItem>
          <MenuItem sx={{ py: '4px' }} value="es">
            <Typography variant="body1" color="text">
              Español
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  )
}

export default LanguageSelector
