import React from 'react'

import { Box, Grid, Paper } from '@mui/material'

import { KeyedMutator } from 'swr'

import Station from 'components/station/Station'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard } from 'contexts/ClipboardContext'
import { useHeaderHeight } from 'contexts/HeaderHeight'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import orderingHelper from 'utils/orderingHelper'
import useWindowDimensions from 'utils/useWindowDimensions'

import IStudyData from 'types/IStudyData'

import { stationGridItemStyle } from 'shared/components/stationStyle'

export type StationDroppableProps = {
  study: IStudyData
  stationRow: number
  stationColumn: number
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

function StationDroppable({
  study,
  stationRow,
  stationColumn,
  mutateStudy,
  clipboard,
  viewType,
}: StationDroppableProps): JSX.Element {
  const { height: topHeaderHeight } = useHeaderHeight()

  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()
  const { isPinnedStationDrawerOpen } = useStudyStatesContext()

  const { height: screenHeight } = useWindowDimensions()

  const stationKey = `station-grid-item${
    viewType === 'pin' ? '-pin' : ''
  }-${stationRow}-${stationColumn}`

  const station = orderingHelper.findStationByRowColumn(study.stations, stationRow, stationColumn)

  const isStationGridItemActive = (gridItemKey: string) =>
    !!actionMenuContext &&
    actionMenuContext.stationGridItem === gridItemKey &&
    actionMenuContext.station === null

  const handleStationGridItemClick = (e: React.MouseEvent<HTMLDivElement>, gridItemKey: string) => {
    e.preventDefault()
    e.stopPropagation()

    if (station) {
      return
    }

    setActionMenuContext({
      stationGridItem: gridItemKey,
      station: null,
      toolGridItem: null,
      tool: null,
      stationListId: null,
      term: null,
    })
  }

  return (
    <Grid
      item
      sx={stationGridItemStyle(
        study.column_count ?? 0,
        isStationGridItemActive(stationKey),
        !!station,
        study.row_count,
        screenHeight,
        stationColumn,
        stationRow,
        isPinnedStationDrawerOpen,
        viewType,
        study.stations.filter((stationObject) => stationObject.is_pinned).length,
        topHeaderHeight,
      )}
      onClick={(e) => handleStationGridItemClick(e, stationKey)}
    >
      <Paper className="MuiPaper-station">
        {(viewType === 'pin' || station) && (
          <Box display="flex" sx={{ height: '100%' }}>
            <Station
              station={station}
              study={study}
              mutateStudy={mutateStudy}
              clipboard={clipboard}
              viewType={viewType}
            />
          </Box>
        )}
      </Paper>
    </Grid>
  )
}

export default React.memo(StationDroppable)
