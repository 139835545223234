import { t } from '@lingui/macro'
import { IconButton, Tooltip } from '@mui/material'

import { ArrowBack } from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'

import IStudyData from 'types/IStudyData'

import paths from 'utils/paths'

interface StudyBackProps {
  study: IStudyData
}

function StudyBack({ study }: StudyBackProps) {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(paths.frontend.studyList(study.study_list))
  }

  return (
    <Tooltip title={t`Back`}>
      <IconButton color="inherit" onClick={handleClick} sx={{ padding: 0 }}>
        <ArrowBack />
      </IconButton>
    </Tooltip>
  )
}

export default StudyBack
