import { useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Alert, Box, Button, Link, Grid, Typography } from '@mui/material'
import { t } from '@lingui/macro'
import ProgressBackdrop from '../../components/control/ProgressBackdrop'
import AccountDataService from '../../data-services/AccountDataService'
import IUserData from '../../types/IUserData'
import FormTextField from '../../components/control/FormTextField'

type FormSignUpFields = {
  name: string
  email: string
  password: string
  confirmPassword: string
}

export default function SignUp(): JSX.Element {
  console.log('Load Sign Up Component')

  const navigate: NavigateFunction = useNavigate()
  const [registeredUser, setRegisteredUser] = useState<IUserData | null>(null)
  const [submitWarning, setSubmitWarning] = useState<string>('')
  const [submitError, setSubmitError] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)

  const formSchema = yup.object().shape({
    name: yup
      .string()
      .label(t`The full name`)
      .required()
      .max(50),
    email: yup
      .string()
      .label(t`The email address`)
      .required()
      .email()
      .max(100),
    password: yup
      .string()
      .label(t`The password`)
      .required()
      .max(100)
      .min(8),
    confirmPassword: yup
      .string()
      .label(t`The confirm password`)
      .required()
      .oneOf([yup.ref('password')], t`Passwords does not match`),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<FormSignUpFields>({
    resolver: yupResolver(formSchema),
  })

  const handleFormSubmit = (data: FormSignUpFields): void => {
    setSubmitWarning('')
    setSubmitError('')
    setSubmitting(true)

    AccountDataService.register(data.name, data.email.toLowerCase(), data.password)
      .then((response) => {
        const user: IUserData = response.data
        console.log('Successful registration', user)
        setRegisteredUser({ ...user })
      })
      .catch((err) => {
        console.log('ERROR: An error occurred during registration', err, err.response)
        if (err.response.status === 400 && err.response.data === 'email_already_exists') {
          setSubmitWarning(t`This email is already in use, please choose another one.`)
        } else {
          setSubmitError(t`An error occurred during registration, please try again.`)
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const handleGoToLogin = (): void => {
    navigate('/auth/login/')
  }

  return (
    <>
      {registeredUser ? (
        <>
          <Typography component="h1" variant="h6">
            {t`Confirm your email`}
          </Typography>
          <Alert severity="success" sx={{ mt: 3, width: '100%' }}>
            {t`We have sent an email to you for verification at ${registeredUser.email}.`}
          </Alert>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            {t`Follow the link provided to finalize the sign up process.`}
          </Box>
          <Box sx={{ mt: 3, textAlign: 'center', width: '100%' }}>
            <Button color="primary" fullWidth onClick={() => handleGoToLogin()} variant="contained">
              {t`Sign In`}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography component="h1" variant="h6">
            {t`Sign Up`}
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit(handleFormSubmit)}
            sx={{ mt: 1, width: '100%' }}
          >
            <FormTextField
              autoFocus
              errorText={errors.name?.message}
              label={t`Full Name`}
              margin="normal"
              registerReturn={register('name')}
            />
            <FormTextField
              errorText={errors.email?.message}
              label={t`Email Address`}
              margin="normal"
              registerReturn={register('email')}
            />
            <FormTextField
              errorText={errors.password?.message}
              label={t`Password`}
              margin="normal"
              registerReturn={register('password')}
              type="password"
            />
            <Box sx={{ color: '#999', fontSize: '11px' }}>
              {t`Your password must contain at least ${8} characters.`}
            </Box>
            <FormTextField
              errorText={errors.confirmPassword?.message}
              label={t`Confirm Password`}
              margin="normal"
              registerReturn={register('confirmPassword')}
              type="password"
            />
            <Box sx={{ color: '#999', fontSize: '11px' }}>
              {t`Enter the same password as before, for verification.`}
            </Box>
            {submitWarning !== '' && (
              <Alert severity="warning" sx={{ mt: 2 }}>
                {submitWarning}
              </Alert>
            )}
            {submitError !== '' && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {submitError}
              </Alert>
            )}
            <Button
              color="primary"
              fullWidth
              sx={{ mb: 2, mt: 3 }}
              type="submit"
              variant="contained"
            >
              {t`Sign Up`}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/auth/login/" variant="body2">
                  {t`Already have an account? Sign In`}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <ProgressBackdrop open={submitting} />
    </>
  )
}
